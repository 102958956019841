import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { cityJobs, cityMax } from "../utils/Data";
import Card from "./Card";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { client } from "../client";
import Pagination from "./Pagination";

function JobCardRender(job) {
  return (
    <Card
      key={job._id}
      btnId={job._id}
      title={job.title}
      categories={job.categories}
      city={job.city}
      salary={job.salary}
      postedOn={job.postedOn}
    />
  );
}

function CityJobs() {
  const history = useNavigate();
  const { city } = useParams();
  const [jobsArr, setJobsArr] = useState([]);

  const [currentPage, setCurrentPage] = useState(0)
  const [maxOpenings, setMaxOpenings] = useState()

  function activePageinc (){
    if(currentPage < ((maxOpenings/10)-1))
      setCurrentPage(currentPage+1)
  }

  function activePagedec(){

    if(currentPage != 0 ) {
      setCurrentPage(currentPage-1)
    }
    
  }

  useEffect(() => {
    client
      .fetch(cityJobs(city,currentPage))
      .then((jobs) => {
        setJobsArr(jobs);
      })
      .catch((err) => console.log(err));

    client.fetch(cityMax(city))
      .then((res)=> {
        setMaxOpenings(res)
      })
  }, [currentPage]);

 
  if(jobsArr.length <1){
    return( 
      <div className=" flex flex-col items-center justify-center my-56">
          
          <p className="text-gray-800 text-lg mb-2">Invalid Search!</p>
          
            <button onClick={() => history(-1)} className="px-4 py-1 bg-primary rounded text-lg text-white font-semibold flex items-center"><ChevronLeftIcon className="mr-2 text-white h-5 w-5"/>Back </button>
          
        </div>
    )
  }
  return (
    <div className="px-4 mx-4">
      <button onClick={() => history(-1)}>
        <div className="text-lg font-semibold text-gray-900 flex flex-row items-center">
          <ChevronLeftIcon className="text-gray-900 h-5 w-5" />
          <h1>Back</h1>
        </div>
      </button>
        <h1 className="font-bold text-xl text-primary font-[Poppins] mt-8 md:px-16 md:mx-16 px-2 mx-2">Showing Results for {city}</h1>
      <div className="md:px-16 md:mx-16 px-2 mx-2 mt-8 mb-16 ">
          {
             jobsArr.map(JobCardRender)
          }
          
          </div>
          <Pagination
             currentPage={currentPage}
             increment={activePageinc}
             decrement={activePagedec}
             maxOpenings={maxOpenings}
          />
    </div>
  );
}

export default CityJobs;
