import React from 'react';
import newres from '../assets/newres.png';
import findjob from '../assets/findjob.png';
import language from '../assets/language.png';

const Resources = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="flex flex-wrap w-full mt-20 flex-col items-center text-center">
        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
          Resources
        </h1>
        <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">
          Need help? check out the resources below!
        </p>
      </div>
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
          <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div className="rounded-lg h-64 overflow-hidden">
              <img
                alt="content"
                className="object-cover object-center h-full w-full"
                src={findjob}
              />
            </div>
            <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
              Looking for jobs in Canada?
            </h2>
            <p className="text-base leading-relaxed mt-2">
              Learn about job search skills, how to get a job,workplace
              standards and Canadian workplace culture.
            </p>
            <a
              rel="noreferrer"
              href="https://www.canada.ca/en/immigration-refugees-citizenship/services/new-immigrants/prepare-life-canada/prepare-work/look-jobs.html"
              className="text-green-500 inline-flex items-center mt-3"
            >
              Learn More
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div className="rounded-lg h-64 overflow-hidden">
              <img
                alt="content"
                className="object-cover object-center h-full w-full"
                src={language}
              />
            </div>
            <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
              Looking to improve your language skills?
            </h2>
            <p className="text-base leading-relaxed mt-2">
              English or French language skills are very important to help you
              settle in Canada. You may choose to focus on learning or improving
              one or the other. This will likely depend on which of the two
              languages most people speak in the area where you live.
            </p>
            <a
              rel="noreferrer"
              href="https://www.canada.ca/en/immigration-refugees-citizenship/services/new-immigrants/new-life-canada/improve-english-french.html"
              className="cursor-pointer text-green-500 inline-flex items-center mt-3"
            >
              Learn More
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div className="rounded-lg h-64 overflow-hidden">
              <img
                alt="content"
                className="object-cover object-center h-full w-full"
                src={newres}
              />
            </div>
            <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
              Find free newcomer services near you.
            </h2>
            <p className="text-base leading-relaxed mt-2">
              Friendly and experienced settlement professionals can help you get
              started in Canada.
            </p>
            <a
              rel="noreferrer"
              href="https://ircc.canada.ca/english/newcomers/services/index.asp"
              className="cursor-pointer text-green-500 inline-flex items-center mt-3"
            >
              Learn More
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resources;
